
.table-primary {
  .ant-table {
    color: #333333;
    padding-bottom: 0px!important;

    .ant-table-container::before, .ant-table-container::after {
      box-shadow: none;
    }

    .ant-table-tbody .ant-table-cell {
      vertical-align: top;
    }
  }

  .ant-table-pagination.ant-pagination{
    margin: 16px;
  }
}
.ant-table-thead > tr > th {
  text-transform: uppercase;
  font-size:12px;
}

.ant-table-tbody > tr > td {
  font-size:13px;
}


.table-responsive .ant-table-content .ant-table-tbody .ant-table-cell {
  white-space: normal;
  padding: 6px 8px;
}

.modal-second .ant-modal-content{
  background-color: #F4F5F7;
}

.modal-full{
  max-width: 100%;
  border-radius: 0;
}
.modal-full .ant-modal-body{
  padding: 0px;
}
.news-modal{
  height: 100vh;
}
.news-modal .ant-modal-content{
  background: none;
  box-shadow: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9ca3af; /* Gray-400 */
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e5e7eb; /* Gray-200 */
}


.ant-select-multiple .ant-select-selection-item {
  line-height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  height: inherit!important;
}

.login-bg {
  position: absolute;
  filter: blur(140px);
  z-index: -1;
  inset: 0px;
  overflow: hidden;
}

.login-bg::before {
  content: " ";
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: rgb(247, 220, 179);
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 1;
}

.login-bg-1 {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: rgb(192, 229, 217);
  margin-left: 160px;
  position: absolute;
  bottom: 180px;
  opacity: 1;
}

.login-bg-2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgb(231, 103, 103);
  position: absolute;
  bottom: 0px;
  left: -50px;
  opacity: 1;
}

.gjs-pn-buttons .gjs-pn-btn{
  font-size: 14px;
}

.file-manager.ant-tree .ant-tree-treenode{
  padding-bottom: 7px;
}

.file-manager .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 7px;
}

.file-manager .ant-tree-switcher{
  width: 3px;
  margin-left: 10px;
  line-height: 16px;
}


.file-manager.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  margin-right: 8px;
}


.file-manager.ant-tree .ant-tree-node-content-wrapper .ant-tree-title {
  margin-right: 8px;
  font-weight: 300;
}


.file-manager.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.file-manager.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #C2E7FF;
  border-radius: 20px;
}

.file-manager.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-title,
.file-manager.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-iconEle{
  color: #333;
}

.file-manager .ant-tree-treenode-selected .ant-tree-switcher .ant-tree-switcher-icon {
  color: #333;
}

.file-manager-layout .ant-layout-sider{
  box-shadow: none!important;
  padding: inherit!important;
}

.table-file-manager td{
  padding: 7px 2px;
  border-bottom: 1px solid #ccc;
}

img.ant-image-preview-img { display: inline-block; }

.FIE_topbar-history-buttons {
  margin-right: 20px;
}



.contexify {
  padding: 5px 0!important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2)!important;
  border-radius: 4px!important;
}

.contexify .contexify_itemContent {
  font-size: 13px;
  text-decoration: none;
  padding: 6px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
}

.contexify .contexify_separator{
  margin-right: 0px;
  margin-left: 0px;
}

.contexify .contexify_item:not(.contexify_item--disabled):hover > .contexify_itemContent,
.contexify .contexify_item:not(.contexify_item--disabled):focus > .contexify_itemContent {
  background-color: #e4e4e4;
  border-radius: 0;
  color: #333;
}


.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu-item--disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.react-contextmenu-submenu {
  position: relative;
}

.react-contextmenu-submenu:hover > .react-contextmenu-item {
  background-color: #f1f1f1;
}

.react-contextmenu-submenu .react-contextmenu-item {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  border: solid 1px #ccc;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 0;
  opacity: 0;
  visibility: hidden;
}


.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: '';
  border-style: solid;
  border-width: 5px 8px;
  border-color: transparent transparent transparent #000;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  transition: 0.2s;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid #ccc;
}

#no-renderer{
  margin: 50px auto;
}

#no-renderer-download{
  margin-top: 20px;
}

/*.ant-layout {*/
/*  background: inherit!important;*/
/*}*/
body{
  background: #F4F5F7!important;
}

.modal-custom-close-icon .ant-modal-close-x{
  width: 30px;
  height: 30px;
}

.ant-select-sm .ant-select-selection-item-content{
  line-height: 13px;
  font-size: 12px;
}

.ant-select-sm .ant-select-selection-search-input, .ant-select-sm  .ant-select-selection-search{
  height: 13px!important;
}